// let ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/c'
let ROOT_URL = "";
let IMAGE_URL = "";
/* if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'){

    // ROOT_URL ='https://jsonplaceholder.typicode.com'
}else{
    // TODO: Server API URL
    // ROOT_URL ='https://jsonplaceholder.typicode.com'

} */

if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://api.tcgdob.com/api/v1";
} else if (process.env.REACT_APP_SERVER?.trim() == "staging") {
  ROOT_URL = "https://betaapi.tcgdob.com/api/v1";
} else {
  ROOT_URL = "http://localhost:9002/api/v1";
}
// ROOT_URL = "https://betaapi.tcgdob.com/api/v1"
// ROOT_URL = "http://localhost:9002/api/v1";
export { IMAGE_URL };
export default ROOT_URL;
