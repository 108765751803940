import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/theme";
import { IMAGE_URL } from "../services/api-url";
import PageBreadCrumbs, { PageName } from "./headerBreadcrumbs";
import { Menu } from "../components/common/sidebarMenu";

const Notification = [
    {
        Avatar: "assets/images/avatar-3.jpg",
        Name: "John Doe",
        Description: "Lorem ipsum dolor sit amet,consectetuer elit.",
        NotificDate: "02 Jan 2022",
    },
    {
        Avatar: "assets/images/avatar-3.jpg",
        Name: "Tailor",
        Description: "Lorem ipsum dolor sit amet,consectetuer elit.",
        NotificDate: "03 Jan 2022",
    },
    {
        Avatar: "assets/images/avatar-3.jpg",
        Name: "Cherrry Warner",
        Description: "Lorem ipsum dolor sit amet,consectetuer elit.",
        NotificDate: "4 Jan 2022",
    },
];

const Setting = [
    // {
    //     title: "Change Password",
    //     icon: "icon-key",
    //     to: "/change-password",
    // },
    {
        title: "Logout",
        icon: "icon-log-out",
        to: "/login",
    },
];

function TopNavbar() {
    const { userInfo, MiniSidebar, setMiniSidebar, setAuth } = useContext(UserContext);
    const navigate = useNavigate()
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };
    function ToggleSidebar() {
        setMiniSidebar(!MiniSidebar);
    }
    const Logout = () => {
        localStorage.removeItem("token");
        setAuth(false);
        navigate('/login')
    };

    return (
        <nav className="navbar header-navbar">
            <div className="navbar-wrapper w-100">
                <div className="navbar-container container-fluid">
                    <div className="row w-100 align-items-center">
                        <div className="col-2">
                            <div className="nav-left mr-auto">
                                <div className="menu-hamburger ml-1 fs-5">
                                    <span className="icon-menu" onClick={() => ToggleSidebar()}></span>
                                    <span className="icon-x" onClick={() => ToggleSidebar()}></span>
                                </div>
                                <div className="slideUp_header  ml-1 fs-5">
                                    <span className="icon-arrow-up"></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-10">
                            <ul className="nav-right d-flex align-items-center justify-content-end mb-0">
                                <li className="user-profile header-notification">
                                    <div className="dropdown-primary dropdown">
                                        <div className="dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            {userInfo.userImage?.path ? <img src={`${IMAGE_URL}${userInfo.userImage?.path}`} className="img-radius img-fluid" alt="User-Profile" /> : <img src={`/assets/images/user.png`} className="img-radius img-fluid" alt="User-Profile" />}
                                            <span>{userInfo?.firstName} {userInfo?.lastName}</span>
                                            <i className="feather icon-chevron-down"></i>
                                        </div>
                                        <ul className="show-notification dropdown-menu-end profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" aria-labelledby="dropdownMenuLink">

                                                    <li>
                                                        <Link to="/admin/profile">
                                                            <i className="icon-user"></i> Profile
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/change-password">
                                                            <i className="fa fa-key"></i> Change Password
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => Logout()} style={{"cursor": "pointer"}}>
                                                            <i className="icon-log-out" ></i> Logout
                                                        </a>
                                                    </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default TopNavbar;
