import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";
import PrivateRoute from "./privateRoute";


const Login = React.lazy(() => import("../pages/login"));
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const UploadCsv = React.lazy(() => import("../pages/product/uploadcsv"));
const ProductsList = React.lazy(() => import("../pages/product/list"));
const ProductsEdit = React.lazy(() => import("../pages/product/view"));
const ChangePassword = React.lazy(() => import("../pages/change-password"));
const AdminsList = React.lazy(() => import("../pages/admins/list"));
const AdminAdd = React.lazy(() => import("../pages/admins/add"));
const AdminProfileUpdate = React.lazy(() => import("../pages/admins/profile"));
const AdminEdit = React.lazy(() => import("../pages/admins/edit"));
const Comments = React.lazy(() => import("../pages/comments/list"));
const CommentsAdd = React.lazy(() => import("../pages/comments/add"));
const UsersList = React.lazy(() => import("../pages/users/list"));
const UserEdit = React.lazy(() => import("../pages/users/edit"));
const ClaimList = React.lazy(() => import("../pages/claim-building/list"));
const ClaimEdit = React.lazy(() => import("../pages/claim-building/edit"));

const Error404 = React.lazy(() => import("../pages/errors/error404"));

// dashboards
const dashboardRoute = {
    path: "/dashboard",
    name: "Dashboard",
    icon: "airplay",
    header: "Navigation",
    element: <Dashboard />,
    route: PrivateRoute,
    children: [
      {
        path: "/",
        name: "Root",
        element: <Navigate to="/dashboard" />,
        route: PrivateRoute
      },
      {
        path: "/change-password",
        name: "Root",
        element: <ChangePassword />,
        route: PrivateRoute
      }
    ]
};
const productRoute = {
      path: "/products",
      name: "Buildings",
      element: <ProductsList />,
      route: PrivateRoute,
      children: [
        {
          path: "/products/add",
          name: "Building Add",
          element: <ProductsEdit />,
          route: PrivateRoute
        },
        {
          path: "/products/uploadcsv",
          name: "Upload Csv",
          element: <UploadCsv />,
          route: PrivateRoute
        },
        {
          path: "/product/view/:id",
          name: "Building View",
          element: <ProductsEdit />,
          route: PrivateRoute
        }
      ]
};
const commentsRoute = {
  path: "/comments/list/:buildingId",
  name: "Comments",
  element: <Comments />,
  route: PrivateRoute,
  children: [
    {
      path: "/comments/add/:buildingId",
      name: "Building Add",
      element: <CommentsAdd />,
      route: PrivateRoute
    },
    {
      path: "/comments/edit/:buildingId/:commentId",
      name: "Building Edit",
      element: <CommentsAdd />,
      route: PrivateRoute
    },
  ]
}
const adminRoleRoute = {
      path: "/admins",
      name: "Admin",
      element: <AdminsList />,
      route: PrivateRoute,
      children: [
        {
          path: "/admin/create",
          name: "Create Role",
          element: <AdminAdd />,
          route: PrivateRoute
        },
        {
          path: "/admin/profile",
          name: "Update Profile",
          element: <AdminProfileUpdate />,
          route: PrivateRoute
        },
        {
          path: "/admin/view/:id",
          name: "Edit Admin",
          element: <AdminEdit />,
          route: PrivateRoute
        },
        {
          path: "/admin/change-password/:id",
          name: "Edit Admin",
          element: <ChangePassword />,
          route: PrivateRoute
        }
      ]
};
const userRoute = {
  path: "/users",
  name: "Users",
  element: <UsersList />,
  route: PrivateRoute,
  children: [
    {
      path: "/user/view/:id",
      name: "Edit Admin",
      element: <UserEdit />,
      route: PrivateRoute
    },
  ]
};
const ClaimRoute = {
  path: "/claim-building",
  name: "Building Claim",
  element: <ClaimList />,
  route: PrivateRoute,
  children: [
    {
      path: "/claim-building/view/:id",
      name: "Edit Building Claim",
      element: <ClaimEdit />,
      route: PrivateRoute
    },
  ]
};

const appRoutes = [
    dashboardRoute,
    productRoute,
    commentsRoute,
    adminRoleRoute,
    userRoute,
    ClaimRoute
];



// auth
const authRoutes = [
  {
    path: "/login",
    name: "Login",
    element: <Login />,
    route: Route
  }
];
// public routes
const otherPublicRoutes = [
    {
      path: "*",
      name: "Error - 404",
      element: <Error404 />,
      route: Route
    }
  ];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  ...appRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
